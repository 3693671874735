import React from 'react';
import styled, { withTheme } from 'styled-components';
import { Box, Text, TextInput, Button, Select, CheckBox, RadioButtonGroup } from 'grommet';
import { Formik, Field } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import FormField from 'granite/components/FormField';
import DateTimePickerAtom from 'generics/DateTimePickerFormik';
import GenericWindowPostMessage from 'pages/components/GenericWindowPostMessage';
import Divider from 'granite/components/Divider';
import ToolTip from 'pages/components/ToolTip';

const options = [
  { label: 'Manual', value: 'MANUAL' },
  { label: 'Yearly', value: 'YEARLY' },
  { label: 'Every Pay Period', value: 'EVERY_PAY_PERIOD' },
  { label: 'Based on Hours Worked', value: 'BASED_ON_HOURS_WORKED' },
];

const StyledCheckBox = styled(CheckBox)`
  & + div {
    height: 15px;
    width: 15px;
  }
`;

const StyledRadioButtonGroup = styled(RadioButtonGroup)`
  & > label > div {
    height: 15px;
    width: 15px;
    font-size: 14 px;
  }

  ,
  & > label {
    font-size: 14px;
  }
`;
const StyledSelect = styled(Select)`
  & > label > div > div > svg {
    height: 15px;
    width: 15px;
    border-box: 2px solid red;
  }
`;
const AccuralPolicyPage = ({
  data,
  setActiveStep,
  eventEmitter,
  updatePtoPolicy,
  dateFormat,
  setSubmitting,
  submitting,
  disabled,
  dateFormatDisplay,
}) => {
  const previousPage = () => {
    GenericWindowPostMessage('POLICY_TAB', { policyId: data.id });
    setActiveStep(0);
  };

  const onSubmit = async (values, { setErrors }) => {
    if (disabled) {
      GenericWindowPostMessage('BALANCE_TAB', {
        policyId: values?.id,
      });
      setActiveStep(2);
      return;
    }
    setErrors({});
    setSubmitting(true);
    const params =
      values.accural_type === 'MANUAL'
        ? { accural_type: values.accural_type ? values.accural_type : '' }
        : values.accural_type === 'YEARLY'
        ? {
            accural_type: values.accural_type ? values.accural_type : '',
            accural_config: {
              total_accural_hours: parseInt(values.total_accural_hours),
            },
            accural_date:
              values.accural_on_hire_date === 'true' ? null : moment(values.accural_date).format('YYYY-MM-DD'),
            accural_on_hire_date: values.accural_on_hire_date === 'true' ? true : false,
            allow_entries_outside_employee_current_accural_year:
              values.allow_entries_outside_employee_current_accural_year,
            use_or_lose_it: values.use_it_or_lose_it,
          }
        : values.accural_type === 'EVERY_PAY_PERIOD'
        ? {
            accural_type: values.accural_type ? values.accural_type : '',
            accural_config: {
              total_accural_hours: parseInt(values.total_accural_hours),
              every_pay_period_per: values.every_pay_period_per,
              accure_day_wise: parseInt(values.accure_day_wise),
            },
            accural_date:
              values.accural_on_hire_date === 'true' ? null : moment(values.accural_date).format('YYYY-MM-DD'),

            accural_on_hire_date: values.accural_on_hire_date === 'true' ? true : false,
            use_or_lose_it: values.use_it_or_lose_it,
          }
        : {
            accural_type: values.accural_type ? values.accural_type : '',
            accural_config: {
              total_accural_hours: parseInt(values.total_accural_hours),
              every_pay_period_per: values.every_pay_period_per,
              based_on_hours_worked_per: parseInt(values.based_on_hours_worked_per),
              accure_day_wise: parseInt(values.accure_day_wise),
            },
            use_or_lose_it: values.use_it_or_lose_it,
            accural_date: values.accural_on_hire_date === 'true' ? null : moment(values.accural_date).format('YYYY-MM-DD'),
            accural_on_hire_date: values.accural_on_hire_date === 'true' ? true : false,
          };

    updatePtoPolicy(eventEmitter, params, values.id, 1);
  };
  const validationSchema = Yup.object().shape({
    total_accural_hours: Yup.number()
      .typeError('Accrual hours must be a number')
      .required('Accrual hours is required')
      .min(0, 'Accrual hours must be positive')
      .integer('Accrual hours should be integer'),
    based_on_hours_worked_per: Yup.number()
      .typeError('Hours worked must be a number')
      .min(0, 'Hours worked must be positive')
      .integer('Accrual hours should be integer'),
  });
  return (
    <Formik
      initialValues={{
        accural_type: data.accural_type ? data.accural_type : 'YEARLY',
        total_accural_hours:
          data.accural_config?.total_accural_hours === undefined ? 0 : data.accural_config?.total_accural_hours,
        use_it_or_lose_it: data ? data.use_or_lose_it : false,
        accure_day_wise:
          data.accural_config?.accure_day_wise === undefined ? 5 : parseInt(data.accural_config.accure_day_wise),
        every_pay_period_per:
          data.accural_config?.every_pay_period_per === undefined ? 'year' : data.accural_config?.every_pay_period_per,
        based_on_hours_worked_per:
          data.accural_config?.based_on_hours_worked_per === undefined
            ? 0
            : data.accural_config?.based_on_hours_worked_per,
        accural_date: data?.accural_date ? moment(data?.accural_date).format(dateFormatDisplay) : '',
        accural_date_check: false,
        accural_on_hire_date: data.accural_on_hire_date ? 'true' : 'false',
        allow_entries_outside_employee_current_accural_year: data
          ? data.allow_entries_outside_employee_current_accural_year
          : false,
        id: data.id,
      }}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <Box direction="row" pad={{ top: 'small', left: 'medium', bottom: 'medium' }}>
            <FormField label="Accrual Type">
              <Box direction="row" width="100%" gap="small" align="center">
                <Select
                  name="accural_type"
                  options={options}
                  onChange={handleChange}
                  labelKey="label"
                  valueKey={{ key: 'value', reduce: true }}
                  placeholder={'Select Accrual Type'}
                  value={values.accural_type}
                  disabled={disabled}
                />
                <ToolTip
                  tipMessage={`Select your accrual type. You may accrue yearly, hourly, per pay period, or not at all. ${values.accural_type === 'EVERY_PAY_PERIOD' ? 'If you have no pay period setup, the accrual would take place weekly':''}`}
                  type="Info"
                />
              </Box>
            </FormField>
          </Box>
          {values.accural_type !== 'MANUAL' && (
            <Box>
              <Divider background="#DCDCDC" height="2px" />
              <Box pad={{ top: 'medium', left: 'medium' }}>
                <Text size="14px" color="black">
                  Accrual Settings :
                </Text>
              </Box>
              <Box width="xlarge" pad={{ top: 'small', left: 'medium' }}>
                {values.accural_type === 'YEARLY' && (
                  <Box direction="row" gap="small" align="center">
                    <Text color="#242424" margin={{ top: 'xxsmall' }}>
                      Accrue
                    </Text>
                    <Box margin={{ left: 'xsmall', right: 'xsmall' }}>
                      <TextInput
                        style={
                          errors.total_accural_hours
                            ? {
                                width: '50px',
                                height: '22px',
                                border: '1px solid #d9534f',
                              }
                            : { width: '50px', height: '22px' }
                        }
                        name="total_accural_hours"
                        id="total_accural_hours"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.total_accural_hours}
                        disabled={disabled}
                        error={touched.total_accural_hours && errors.total_accural_hours}
                      />
                    </Box>
                    <Text color="#242424" margin={{ top: 'xxsmall' }}>
                      hours per year
                    </Text>
                    <ToolTip
                      tipMessage="Enter the total number of hours that should accrue in one year ."
                      type="Info"
                    />
                  </Box>
                )}
                {values.accural_type === 'YEARLY' && touched.total_accural_hours && errors.total_accural_hours ? (
                  <Text className="invalid-feedback" color="#d9534f !important">
                    {errors.total_accural_hours}
                  </Text>
                ) : null}
                {values.accural_type !== 'YEARLY' && (
                  <Box>
                    <Box
                      direction="row"
                      margin={{ bottom: 'xsmall' }}
                      pad={{ top: 'xsmall' }}
                      gap="small"
                      alignContent="center"
                    >
                      <Text color="#242424">Accruals will take place</Text>
                      <Box style={{ width: '50px', height: '22px' }}>
                        <Select
                          style={{ width: '20px', height: '10px' }}
                          name="accure_day_wise"
                          options={[5, 4, 3, 2, 1]}
                          onChange={handleChange}
                          value={parseInt(values.accure_day_wise)}
                          disabled={disabled}
                        />
                      </Box>
                      <Text color="#242424">day's after the end of each pay period .</Text>
                    </Box>
                    {values.accural_type === 'EVERY_PAY_PERIOD' ? (
                      <Box direction="row" pad={{ top: 'xsmall' }} gap="small" alignContent="center" align="center">
                        <Text color="#242424">Accrue</Text>
                        <Box margin={{ left: 'xsmall', right: 'xsmall' }}>
                          <TextInput
                            style={
                              errors.total_accural_hours
                                ? {
                                    width: '50px',
                                    height: '24px',
                                    border: '1px solid #d9534f',
                                  }
                                : { width: '50px', height: '24px' }
                            }
                            name="total_accural_hours"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.total_accural_hours}
                            disabled={disabled}
                          />
                        </Box>
                        <Text color="#242424">hours </Text>
                        <StyledSelect
                          style={{ width: '70px', height: '20px' }}
                          name="every_pay_period_per"
                          options={[{ value: 'year', label: 'Per Year' }]}
                          onChange={handleChange}
                          labelKey="label"
                          valueKey={{ key: 'value', reduce: true }}
                          value={values.every_pay_period_per}
                          disabled={disabled}
                        />
                        <ToolTip
                          tipMessage="Enter the total number of hours that should accrue.
                                             If 'per year' is selected, we'll automatically calculate the number of hours to add each pay period ."
                          type="Info"
                        />
                      </Box>
                    ) : (
                      <Box direction="row" margin={{ bottom: 'xsmall' }} gap="small" align="center">
                        <Text color="#242424" margin={{ top: 'xxsmall' }}>
                          Accrue
                        </Text>
                        <Box margin={{ left: 'xsmall', right: 'xsmall' }}>
                          <TextInput
                            style={
                              errors.total_accural_hours
                                ? {
                                    width: '50px',
                                    height: '22px',
                                    border: '1px solid #d9534f',
                                  }
                                : { width: '50px', height: '22px' }
                            }
                            name="total_accural_hours"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.total_accural_hours}
                            disabled={disabled}
                          />
                        </Box>
                        <Text color="#242424" margin={{ top: 'xxsmall' }}>
                          hours per
                        </Text>
                        <Box margin={{ left: 'xsmall', right: 'xsmall' }}>
                          <TextInput
                            style={
                              errors.based_on_hours_worked_per
                                ? {
                                    width: '50px',
                                    height: '22px',
                                    border: '1px solid #d9534f',
                                  }
                                : { width: '50px', height: '22px' }
                            }
                            name="based_on_hours_worked_per"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.based_on_hours_worked_per}
                            disabled={disabled}
                          />
                        </Box>
                        <Text color="#242424" margin={{ top: 'xxsmall' }}>
                          hours worked
                        </Text>
                        <ToolTip
                          tipMessage="Enter the number of hours that will accrue when the employee has worked the specified number of hours ."
                          type="Info"
                        />
                      </Box>
                    )}
                  </Box>
                )}
                {values.accural_type !== 'YEARLY' && touched.total_accural_hours && errors.total_accural_hours ? (
                  <Text className="invalid-feedback" color="#d9534f !important">
                    {errors.total_accural_hours}
                  </Text>
                ) : null}
                {values.accural_type === 'BASED_ON_HOURS_WORKED' &&
                touched.based_on_hours_worked_per &&
                errors.based_on_hours_worked_per ? (
                  <Text className="invalid-feedback" color="#d9534f !important">
                    {errors.based_on_hours_worked_per}
                  </Text>
                ) : null}
                <Box direction="row" pad={{ top: 'small', bottom: 'medium' }} gap="small" align="center">
                  <StyledCheckBox
                    name="use_it_or_lose_it"
                    onChange={handleChange}
                    checked={values.use_it_or_lose_it}
                    disabled={disabled}
                  />
                  <Text color="#242424" pad={{ left: 'xsmall' }} margin={{ top: 'xsmall' }}>
                    {values.accural_type === 'BASED_ON_HOURS_WORKED'
                      ? 'Use it or lose it ( Reset balance on Jan 1st )'
                      : 'Use it or lose it '}
                  </Text>
                  <ToolTip
                    tipMessage="If this box is checked and there is a positive balance.
                                      At the end of year it will get set to zero before accruing new hours.
                                      Warning: This is not recommended for CA users ."
                    type="Info"
                  />
                </Box>
              </Box>
                <Box>
                  <Divider background="#DCDCDC" height="2px" />
                  <Box pad={{ top: 'medium', left: 'medium' }}>
                    <Text size="14px" color="black">
                      Accrual Date Settings :
                    </Text>
                  </Box>
                  <Box pad={{ top: 'medium', left: 'medium' }}>
                    {values.accural_type !== 'EVERY_PAY_PERIOD' ? (
                      <Box direction="row" gap="small" align="center">
                        <Text color="black">Accruals will happen on :</Text>
                        <ToolTip
                          tipMessage="Choose whether you'd like hours to accrue or reset on a team member's hire date, or on a particular date in the year.
                                             If you choose 'hire date' but do not actually set a hire date for a team member.
                                             The system will treat Jan 1st as their hire date ."
                          type="Info"
                        />
                      </Box>
                    ) : (
                      <Text color="#242424">Reset yearly balance on (only if use it or lose it is checked) :</Text>
                    )}
                    <Box>
                      <StyledRadioButtonGroup
                        name="accural_on_hire_date"
                        id="accural_on_hire_date"
                        disabled={disabled}
                        options={[
                          { label: 'Hire Date', value: 'true' },

                          {
                            label: (
                              <Box
                                style={{
                                  background: 'white',
                                  width: '180px',
                                  height: '33px',
                                  border: '0px solid ridge',
                                }}
                                margin="xxsmall"
                                disabled={(values.accural_on_hire_date === 'true' ? true : false) || disabled}
                              >
                                <Field
                                  id="accural_date"
                                  name="accural_date"
                                  placeholder="Select Date"
                                  component={DateTimePickerAtom}
                                  options={{ dateFormat }}
                                  disabled={(values.accural_on_hire_date === 'true' ? true : false) || disabled}
                                />
                              </Box>
                            ),
                            value: 'false',
                          },
                        ]}
                        value={values.accural_on_hire_date}
                        onChange={handleChange}
                        //disabled={values.accural_on_hire_date === 'true' ? true : false}
                      />
                    </Box>
                    {values.accural_type === 'YEARLY' && (
                      <Box direction="row" pad={{ top: 'medium', bottom: 'medium' }} gap="small" align="center">
                        <StyledCheckBox
                          name="allow_entries_outside_employee_current_accural_year"
                          checked={values.allow_entries_outside_employee_current_accural_year}
                          onChange={handleChange}
                          disabled={disabled}
                        />
                        <Text color="#242424" margin={{ left: 'xsmall', top: 'xsmall' }}>
                          Allow entries outside of a team member's current accrual year
                        </Text>
                        <ToolTip
                          tipMessage="When this option is selected, the team member can enter time off for dates that fall outside the current accrual year.
                                             For example, if a team member's time off balance is set to renew on their hire date.
                                             July 15, and today is May 15, they will be able to enter time off for August 15 ."
                          type="Info"
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
            </Box>
          )}
          <Box pad={{ left: 'xlarge' }} direction="row" justify="end">
            <Button
              margin={{ right: 'medium' }}
              label="Previous"
              color="accent-1"
              onClick={previousPage}
              disabled={submitting}
              primary
            />
            <Button
              type="submit"
              label={submitting ? 'Submitting...' : 'Next'}
              color="accent-1"
              disabled={submitting}
              primary
            />
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default withTheme(AccuralPolicyPage);
