// Lib imports
import _ from 'lodash/fp';
// Application imports
import ptoApi from 'pto/gateways/pto-api';
import { PTO_POLICY_EVENTS } from 'pto/constants';

// TODO: update comment when migrating to Granite API
/**
 * OnBoarding Page - Implimentation of user createOrganisation action.
 *
 * @param {Object} organisation data
 * @param {string} organisation.name
 * @param {string} organisation.description
 * @param {string} organisation.address
 *
 * @returns {json} - a object containing success/failure message
 * @throws {GraniteError} if name is invalid.
 */

async function fetchAllPtoPolicy(eventEmitter, params) {
  try {
    eventEmitter.emit(PTO_POLICY_EVENTS.SHOW_LOADER);
    const data = await ptoApi.fetchAllPtoPolicy(params);
    eventEmitter.emit(PTO_POLICY_EVENTS.GET_PTO_POLICY_SUCCESS, data);
    eventEmitter.emit(PTO_POLICY_EVENTS.HIDE_LOADER);
  } catch (err) {
    const errorMessage = err && err?.errors?.non_field;
    eventEmitter.emit(PTO_POLICY_EVENTS.GET_PTO_POLICY_FAILURE, errorMessage);
    eventEmitter.emit(PTO_POLICY_EVENTS.HIDE_LOADER);
  }
}

async function fetchPtoRequestList(eventEmitter, params) {
  try {
    const data = await ptoApi.fetchPtoRequestList(params);
    eventEmitter.emit(PTO_POLICY_EVENTS.GET_PTO_REQUEST_LIST_SUCCESS, data);
  } catch (err) {
    const errorMessage = err && err?.errors?.non_field;
    eventEmitter.emit(PTO_POLICY_EVENTS.GET_PTO_REQUEST_LIST_FAILURE, errorMessage);
  }
}

async function fetchPtoRequest(eventEmitter, params) {
  try {
    const data = await ptoApi.fetchPtoRequest(params);
    eventEmitter.emit(PTO_POLICY_EVENTS.GET_PTO_REQUEST_SUCCESS, data);
  } catch (err) {
    const errorMessage = err && err?.errors?.non_field;
    eventEmitter.emit(PTO_POLICY_EVENTS.GET_PTO_REQUEST_FAILURE, errorMessage);
  }
}

async function fetchPtoTimeCardRequest(eventEmitter, params) {
  try {
    const data = await ptoApi.fetchPtoTimeCardRequest(params);
    eventEmitter.emit(PTO_POLICY_EVENTS.GET_PTO_REQUEST_SUCCESS, data);
  } catch (err) {
    const errorMessage = err && err?.errors?.non_field;
    eventEmitter.emit(PTO_POLICY_EVENTS.GET_PTO_REQUEST_FAILURE, errorMessage);
  }
}

async function fetchPtoPolicy(eventEmitter, params) {
  try {
    if (!params.policyId) {
      eventEmitter.emit(PTO_POLICY_EVENTS.HIDE_LOADER);
      return;
    }

    const data = await ptoApi.fetchPtoPolicy(params);
    eventEmitter.emit(PTO_POLICY_EVENTS.GET_PTO_POLICY_SUCCESS, data);
  } catch (err) {
    const errorMessage = err && err?.errors?.non_field;
    eventEmitter.emit(PTO_POLICY_EVENTS.GET_PTO_POLICY_FAILURE, errorMessage);
  }
}
async function getEmployeePolicy(eventEmitter, params) {
  try {
    const data = await ptoApi.getEmployeePolicy(params);
    eventEmitter.emit(PTO_POLICY_EVENTS.GET_EMPLOYEE_POLICY_SUCCESS, data);
  } catch (err) {
    const errorMessage = err && err?.errors?.non_field;
    eventEmitter.emit(PTO_POLICY_EVENTS.GET_EMPLOYEE_POLICY_FAILURE, errorMessage);
  }
}

async function getAccuralHistory(eventEmitter, params) {
  try {
    const data = await ptoApi.getAccuralHistory(params);
    eventEmitter.emit(PTO_POLICY_EVENTS.GET_ACCURAL_HISTORY_SUCCESS, data);
  } catch (err) {
    const errorMessage = err && err?.errors?.non_field;
    eventEmitter.emit(PTO_POLICY_EVENTS.GET_ACCURAL_HISTORY_FAILURE, errorMessage);
  }
}

async function getEmployeesList(eventEmitter, params) {
  try {
    const data = await ptoApi.getEmployeesList(params);
    eventEmitter.emit(PTO_POLICY_EVENTS.GET_EMPLOYEE_LIST_SUCCESS, data);
  } catch (err) {
    const errorMessage = err && err?.errors?.non_field;
    eventEmitter.emit(PTO_POLICY_EVENTS.GET_EMPLOYEE_LIST_FAILURE, errorMessage);
  }
}

async function deletePtoPolicy(eventEmitter, params) {
  try {
    const data = await ptoApi.deletePtoPolicy(params);
    eventEmitter.emit(PTO_POLICY_EVENTS.DELETE_PTO_POLICY_SUCCESS, data);
  } catch (err) {
    const errorMessage = err && err?.errors?.non_field;
    eventEmitter.emit(PTO_POLICY_EVENTS.DELETE_PTO_POLICY_FAILURE, errorMessage);
  }
}

async function createPtoPolicy(eventEmitter,values, oauthToken) {
  try {
    const data = await ptoApi.createPtoPolicy({ values, authToken: oauthToken });
    eventEmitter.emit(PTO_POLICY_EVENTS.CREATE_PTO_POLICY_SUCCESS, data);

  } catch (err) {
    const errorMessage = err && err?.errors?.errors?.non_field;  
    eventEmitter.emit(PTO_POLICY_EVENTS.CREATE_PTO_POLICY_FAILURE, errorMessage);

  }
}

async function addTimeOff(eventEmitter, params) {
  try {
    const data = await ptoApi.addTimeOff(params);
    eventEmitter.emit(PTO_POLICY_EVENTS.ADD_TIME_OFF_SUCCESS, data);
    return data;
  } catch (err) {
    const errorMessage = err && err?.errors?.non_field;
    eventEmitter.emit(PTO_POLICY_EVENTS.ADD_TIME_OFF_FAILURE, errorMessage);
  }
}

async function updateTimeOff(eventEmitter, params) {
  try {
    const data = await ptoApi.updateTimeOff(params);
    eventEmitter.emit(PTO_POLICY_EVENTS.UPDATE_TIME_OFF_SUCCESS, data);
    return data;
  } catch (err) {
    const errorMessage = err && err?.errors?.non_field;
    eventEmitter.emit(PTO_POLICY_EVENTS.UPDATE_TIME_OFF_FAILURE, errorMessage);
  }
}

async function updateTimeCardTimeOff(eventEmitter, params) {
  try {
    const data = await ptoApi.updateTimeCardTimeOff(params);
    eventEmitter.emit(PTO_POLICY_EVENTS.UPDATE_TIME_OFF_SUCCESS, data);
  } catch (err) {
    const errorMessage = err && err?.errors?.non_field;
    eventEmitter.emit(PTO_POLICY_EVENTS.UPDATE_TIME_OFF_FAILURE, errorMessage);
  }
}

async function updatePtoPolicy(eventEmitter, params, id, key) {
  try {
    const data = await ptoApi.updatePtoPolicy({ params}, id);
    eventEmitter.emit(PTO_POLICY_EVENTS.UPDATE_PTO_POLICY_SUCCESS, {data,key});
    if (key !== 'viewTImeOff') {
      fetchPtoPolicy(eventEmitter, { policyId: id });
    }
  } catch (err) {
    const errorMessage = err && err?.errors?.non_field;
    eventEmitter.emit(PTO_POLICY_EVENTS.UPDATE_PTO_POLICY_FAILURE, errorMessage);
  }
}

async function updateEmployeeBalance(eventEmitter, params) {
  try {
    const data = await ptoApi.updateEmployeeBalance(params);
    eventEmitter.emit(PTO_POLICY_EVENTS.UPDATE_POLICY_BALANCE_SUCCESS, data);
  } catch (err) {
    const errorMessage = err && err?.errors?.non_field;
    eventEmitter.emit(PTO_POLICY_EVENTS.UPDATE_POLICY_BALANCE_FAILURE, {errorMessage,prvData:params.data});
  }
  //fetchPtoPolicy(eventEmitter, {policyId: id, authToken: oauthToken}
}

export {
  fetchAllPtoPolicy,
  deletePtoPolicy,
  createPtoPolicy,
  updatePtoPolicy,
  fetchPtoPolicy,
  fetchPtoRequestList,
  fetchPtoRequest,
  addTimeOff,
  getEmployeePolicy,
  updateTimeOff,
  getEmployeesList,
  getAccuralHistory,
  updateEmployeeBalance,
  updateTimeCardTimeOff,
  fetchPtoTimeCardRequest
};
