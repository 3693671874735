import React, { useState, useEffect, useMemo } from 'react';
import { Box } from 'grommet';

import queryString from 'query-string';

import AddTimeOffRequestSlider from 'pages/components/PTO/AddTimeOffRequestSlider';
import GenericWindowPostMessage from 'pages/components/GenericWindowPostMessage';
import { PTO_POLICY_EVENTS } from 'pto/constants';

import EventEmitter from 'utils/event-emitter';
import { setAuthToken } from 'utils/auth-singleton';
import Singleton from 'utils/singleton';

import { addTimeOff, getEmployeePolicy, updateTimeCardTimeOff, fetchPtoTimeCardRequest } from 'pto/controllers/pto';

let eventEmitter = new EventEmitter();
let dateFormat = '';

const TimecardPaidTimeOffRequest = props => {
  const [employees, setEmployees] = useState([]);
  const [loader, setLoader] = useState(true);
  const [ptoRequestData, setPtoRequestData] = useState();
  const [employeePolicy, setEmployeePolicy] = useState([]);
  const [employeeId, setEmployeeId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const [actionFlag, setActionFlag] = useState();
  const singleton=new Singleton()
  const {
    location: { search },
  } = props;

  useEffect(() => {
    const query = queryString.parse(search);
    setActionFlag(query?.tabId);
    setEmployeeId(query?.employee_id);
    if (singleton.oAuthToken === null) {
      singleton.oAuthToken = query.oAuthToken;
    }
    setSelectedDate(query?.selected_date ?? null);
    setEmployees([{ value: query.employee_id, label: query.employee_name }]);

    getEmployeePolicy(eventEmitter, {
      is_active: true,
      id: query.employee_id,
    });
    if (query?.tabId === 'Edit') {
      fetchPtoTimeCardRequest(eventEmitter, {
        is_active: true,
        id: query.policyId,
      });
    }
  }, [search]);

  useEffect(function init() {
    const observable = eventEmitter.getObservable();
    let subscription;
    listenObservable();

    function listenObservable() {
      subscription = observable.subscribe(event => {
        switch (event.type) {
          case PTO_POLICY_EVENTS.GET_PTO_REQUEST_SUCCESS:
            setPtoRequestData(event.data);
            setLoader(false);
            break;

          case PTO_POLICY_EVENTS.GET_PTO_REQUEST_FAILURE:
            setPtoRequestData([]);
            setLoader(false);
            GenericWindowPostMessage('FAILURE_PTO_TOAST', {
              toastMessage: event.data || 'something went wrong',
            });
            break;

          case PTO_POLICY_EVENTS.ADD_TIME_OFF_SUCCESS:
            GenericWindowPostMessage('SUCCESS_PTO_TOAST', {
              toastMessage: 'Time Off Added Successfully !!!',
              showWarning: event?.data?.show_warning,
              warning: event?.data?.warning
            });
            GenericWindowPostMessage('CLOSE_ADD_TIME_OFF_SLIDER', {});
            setLoading(false);

            break;
          case PTO_POLICY_EVENTS.ADD_TIME_OFF_FAILURE:
            GenericWindowPostMessage('FAILURE_PTO_TOAST', {
              toastMessage: event.data,
            });
            setLoading(false);
            break;

          case PTO_POLICY_EVENTS.UPDATE_TIME_OFF_SUCCESS:
            setLoader(false);
            GenericWindowPostMessage('SUCCESS_PTO_TOAST', {
              toastMessage: 'Time Off Request Updated Successfully',
            });
            setLoading(false);
            break;

          case PTO_POLICY_EVENTS.UPDATE_TIME_OFF_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.data || 'Time Off Request Not Updated',
            });
            setLoading(false);
            break;

          case PTO_POLICY_EVENTS.GET_EMPLOYEE_POLICY_SUCCESS:
            setEmployeePolicy(
              event.data?.policies &&
                event.data?.policies.map(item => ({
                  value: item.policy_id.toString(),
                  label: item.policy_name,
                  remainingHour: item.employee_balance,
                })),
            );
            setLoader(false);
            break;

          case PTO_POLICY_EVENTS.GET_EMPLOYEE_POLICY_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.err || 'Something went wrong',
            });
            break;
          default:
        }
      });
    }

    return () => subscription?.unsubscribe();
  }, []);

  const onSubmit = async values => {
    setLoading(true);
    try {
      let result;
      if (actionFlag === 'Add') {
        result = await addTimeOff(eventEmitter, values);
      } else if (actionFlag === 'Edit') {
        result = await updateTimeCardTimeOff(eventEmitter, values);
      }
    } catch (error) {
      console.error("Error in API call:", error);
    } finally {
      setLoading(false); // Ensure loading is reset
    }
  };

  const initialValues = useMemo(
    () => () => [
      {
        id: ptoRequestData?.id ?? '',
        employee_id: employeeId ?? '',
        policy_id: ptoRequestData?.policy_id.toString() ?? '',
        start_date: selectedDate ?? '',
        end_date: selectedDate ?? '',
        status: ptoRequestData?.status === 'REJECTED' ? 'Rejected' : 'Approved',
        description: ptoRequestData?.employee_note ?? '',
      },
    ],
    [ptoRequestData, employeeId, selectedDate],
  );

  if (loader) {
    return (
      <div className="loadingActivities" role="status">
        <span>Loading...</span>
      </div>
    );
  }

  return (
    <Box margin={{ left: 'medium', right: 'small' }} flex={{ grow: 1 }}>
      <AddTimeOffRequestSlider
        filter={employeeId ? false : true}
        employees={employees}
        initialValues={initialValues()}
        policy={employeePolicy}
        dateFormat={dateFormat}
        handleSubmit={onSubmit}
        mode={actionFlag.toLowerCase()}
        loading={loading}
        selectedDate={selectedDate}
        data={ptoRequestData}
      />
    </Box>
  );
};

export default TimecardPaidTimeOffRequest;
