import React from 'react';
import { Box, Text, TextInput, Button, RadioButtonGroup, Image, Tip } from 'grommet';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import FormField from 'granite/components/FormField';
import Divider from 'granite/components/Divider';
import GenericWindowPostMessage from 'pages/components/GenericWindowPostMessage';

import questionMark from 'pages/addOrEditPtoPolicy/icons/question_mark_PNG99.png';

const StyledRadioButtonGroup = styled(RadioButtonGroup)`
  & > label > div {
    height: 15px;
    width: 15px;
    font-size: 14 px;
  }

  ,
  & > label {
    font-size: 14px;
  }
`;
const StyledTextInput = styled(TextInput)`
  & {
    height: 30px;
    border-radius: 4px 0px 0px 4px;
  }
`;

const BalanceSettingPage = ({
  setActiveStep,
  updatePtoPolicy,
  eventEmitter,
  data,
  submitting,
  setSubmitting,
  disabled,
}) => {
  const validationSchema = Yup.object().shape({
    waiting_period_days: Yup.number()
      .typeError('Waiting period must be a number')
      .moreThan(-1, 'Waiting period cannot be negative')
      .lessThan(365, 'Waiting period can not be greater than 365 days')
      .integer('Waiting period should be integer'),
    carry_over_limit_value: Yup.number()
      .typeError('Carry over limit must be a number')
      .moreThan(-1, 'Carry over limit cannot be negative')
      .lessThan(99999, 'Carry over limit can not be greater than 99999')
      .integer('Carry over limit should be integer'),
    max_balance_limit: Yup.number()
      .typeError('Maximum balance must be a number')
      .moreThan(-1, 'Maximum balance cannot be negative')
      .lessThan(99999, 'Maximum balance can not be greater than 99999')
      .nullable(true)
      .integer('Maximum balance should be integer'),
  });

  const previousPage = () => {
    GenericWindowPostMessage('ACCURAL_TAB', { policyId: data.id });
    setActiveStep(1);
  };
  const onSubmit = async (values, { setErrors }) => {
    if (disabled) {
      GenericWindowPostMessage('EMPLOYEE_TAB', {
        policyId: values?.id,
      });
      setActiveStep(3);
      return;
    }
    setErrors({});
    setSubmitting(true);

    const params = {
      accural_config: {
        waiting_period: values?.waiting_period === 'true' ? true : false,
        waiting_period_days: values.waiting_period_days ? parseInt(values.waiting_period_days) : 0,
        carry_over_limit_value: values.carry_over_limit_value ? parseInt(values.carry_over_limit_value) : 0,
        carry_over_limit: values.carry_over_limit === "Yes there's a limit" ? true : false,
      },
      max_balance_limit: values.max_balance_limit,
    };

    updatePtoPolicy(eventEmitter, params, values.id, 2);
  };
  return (
    <Formik
      initialValues={{
        waiting_period: data.accural_config?.waiting_period === true ? 'true' : 'false',
        waiting_period_days: data.accural_config?.waiting_period_days ? data.accural_config.waiting_period_days : 0,
        carry_over_limit: data.accural_config
          ? data.accural_config.carry_over_limit === true
            ? "Yes there's a limit"
            : 'No, all hours can be carried over'
          : null,
        carry_over_limit_value: data.accural_config?.carry_over_limit_value
          ? data.accural_config.carry_over_limit_value
          : 0,
        max_balance_limit: data.max_balance_limit ? data.max_balance_limit : null,
        id: data.id,
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, handleChange, handleSubmit, isSubmitting, touched, errors }) => (
        <form onSubmit={handleSubmit}>
          <Box pad={{ top: 'medium', left: 'medium' }}>
            <Text size="14px" color="black">
              Waiting Period
            </Text>
          </Box>
          <Box width="large" pad={{ top: 'medium', left: 'medium', bottom: 'medium' }}>
            <Box width="large" direction="column" pad={{ bottom: 'medium' }}>
              <Text color="rgb(99,108,114);">
                Do your employees go through a waiting period before they may use time off ?{' '}
                {/*<Image
                  height="12"
                  width="15"
                  src={questionMark}
                  margin={{left: 'small'}}
                  pad="small"
                  alt="menu"
                />*/}
              </Text>
              <StyledRadioButtonGroup
                name="waiting_period"
                pad={{ top: 'small' }}
                options={[
                  { label: 'Yes', value: 'true' },
                  { label: 'No', value: 'false' },
                ]}
                onChange={handleChange}
                value={values.waiting_period}
                disabled={disabled}
              />
            </Box>
            <Box>
              <FormField
                name="waiting_period_days"
                label={
                  <Text name="waiting_period_days" error={errors.waiting_period_days} size="14px" color="black">
                    Waiting Period Length{' '}
                    {/*<Image
                      height="12"
                      width="15"
                      src={questionMark}
                      margin={{left: 'small'}}
                      pad="small"
                      alt="menu"
                    />*/}
                  </Text>
                }
              >
                <Box direction="row" width="fit-content">
                  <StyledTextInput
                    size="xsmall"
                    name="waiting_period_days"
                    onChange={handleChange}
                    error={errors.waiting_period_days}
                    value={values.waiting_period_days}
                    disabled={disabled ? true : values.waiting_period === 'true' ? false : true}
                  />
                  <Box
                    background="#eee"
                    width="xsmall"
                    align="center"
                    pad={{ top: '2px' }}
                    style={{
                      border: '1px solid #CBCBCB',
                      borderRadius: '0px 4px 4px 0px',
                      opacity: disabled ? 0.3 : values.waiting_period === 'true' ? null : 0.3,
                    }}
                  >
                    <Text> Days</Text>
                  </Box>
                </Box>
              </FormField>
              {errors.waiting_period_days && (
                <Text className="invalid-feedback" color="#d9534f !important">
                  {errors.waiting_period_days}
                </Text>
              )}
            </Box>
          </Box>
          <Divider background="#DCDCDC" height="2px" />
          <Box>
            {!data.use_or_lose_it && (
              <Box>
                <Box pad={{ top: 'medium', left: 'medium' }}>
                  <Text size="14px" color="black">
                    Limited Carryover Hours{' '}
                    {/*<Image
                  height="12"
                  width="15"
                  src={questionMark}
                  margin={{left: 'small'}}
                  pad="small"
                  alt="menu"
                />*/}
                  </Text>
                </Box>
                <Box width="large" direction="column" pad={{ top: 'medium', left: 'medium', bottom: 'medium' }}>
                  <Text color="rgb(99,108,114)">
                    Is there a limit to the number of hours your employees can carryover ?
                  </Text>
                  <StyledRadioButtonGroup
                    name="carry_over_limit"
                    width="medium"
                    pad={{ top: 'medium' }}
                    onChange={handleChange}
                    value={values.carry_over_limit}
                    options={["Yes there's a limit", 'No, all hours can be carried over']}
                    disabled={disabled}
                  />
                </Box>

                <Box width="large" pad={{ left: 'medium' }}>
                  <FormField
                    name="carry_over_limit_values"
                    label={
                      <Text size="14px" color="black">
                        Carryover Limit{' '}
                        {/*<Image
                      height="12"
                      width="15"
                      src={questionMark}
                      margin={{left: 'small'}}
                      pad="small"
                      alt="menu"
                    />*/}
                      </Text>
                    }
                  >
                    <Box direction="row" width="fit-content">
                      <StyledTextInput
                        size="xsmall"
                        name="carry_over_limit_value"
                        id="carry_over_limit_value"
                        onChange={handleChange}
                        value={values.carry_over_limit_value}
                        disabled={disabled ? true : values.carry_over_limit === "Yes there's a limit" ? false : true}
                      />
                      <Box
                        background="#eee"
                        width="fit-content"
                        align="center"
                        pad={{ left: 'medium', right: 'medium', top: 'xxsmall' }}
                        style={{
                          border: '1px solid #CBCBCB',
                          borderRadius: '0px 4px 4px 0px',
                          opacity: disabled ? 0.3 : values.carry_over_limit === "Yes there's a limit" ? null : 0.3,
                        }}
                      >
                        <Text>Hours</Text>
                      </Box>
                    </Box>
                  </FormField>
                  {errors.carry_over_limit_value && (
                    <Text className="invalid-feedback" color="#d9534f !important">
                      {errors.carry_over_limit_value}
                    </Text>
                  )}
                </Box>
              </Box>
            )}
            <Box width="large" pad={{ left: 'medium', top: 'medium' }}>
              <FormField
                label={
                  <Box direction='row' align='center'>
                    <Text size="14px" color="black">
                      Maximum Balance (optional){' '}
                    </Text>
                    <Tip
                      plain
                      content={
                        <Box
                          pad="small"
                          gap="small"
                          width={{ max: 'medium' }}
                          round="small"
                          background="black"
                          responsive={false}
                        >
                          <Text color="white" size="small">
                            The maximum number of hours an employee can accrue before accrual is paused .
                          </Text>
                        </Box>
                      }
                      dropProps={{ align: { left: 'right' } }}
                    >
                      <Image
                        height="12"
                        width="15"
                        src={questionMark}
                        margin={{ left: 'small' }}
                        pad="small"
                        alt="menu"
                        color="#09819a"
                      />
                    </Tip>
                  </Box>
                }
              >
                <Box direction="row" width="fit-content">
                  <StyledTextInput
                    size="xsmall"
                    name="max_balance_limit"
                    placeholder="Maximum Balance"
                    onChange={handleChange}
                    value={values.max_balance_limit}
                    disabled={disabled}
                  />
                  <Box
                    background="#eee"
                    width="fit-content"
                    align="center"
                    pad={{ left: 'medium', right: 'medium', top: 'xxsmall' }}
                    style={{
                      border: '1px solid #CBCBCB',
                      borderRadius: '0px 4px 4px 0px',
                      opacity: !disabled ? null : 0.3,
                    }}
                  >
                    <Text> Hours</Text>
                  </Box>
                </Box>
              </FormField>
              {errors.max_balance_limit && (
                <Text className="invalid-feedback" color="#d9534f !important">
                  {errors.max_balance_limit}
                </Text>
              )}
            </Box>
          </Box>
          <Box pad={{ left: 'xlarge' }} direction="row" justify="end">
            <Button
              margin={{ right: 'medium' }}
              label="Previous"
              color="accent-1"
              onClick={previousPage}
              disabled={submitting}
              primary
            />
            <Button
              type="submit"
              label={submitting ? 'Submitting...' : 'Next'}
              color="accent-1"
              disabled={submitting}
              primary
            />
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default BalanceSettingPage;
